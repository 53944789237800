<template>
  <div id="app" style="background-color:#F6FBFF;">
    <router-view/>
  </div>
</template>

<script>
  export default {
    name: 'AppComponent',
  }
</script>